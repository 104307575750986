    :root {
        --bg-main: #222;
        --bg-secondary: #454545;
        --text-main: #dedede;
        --text-secondary: #898989;
        --sml: #4695D6;
        --sml-transparent: rgba(70, 149, 214, 0.3)
    }

    body {
        overflow-x: hidden;

        background-color: var(--bg-main);
        color: var(--text-main);
    }

    /* Tiny lil margin for headings so that they look nice :) */
    h1,
    h2,
    h3,
    h4,
    h5,
    p {
        margin: 1.5vh 1vw;
    }

    h3 .desc {
        margin: 0px 0px;
    }

    h1 {
        font-size: max(3vw, 4vh);
    }

    h2 {
        font-size: max(2vw, 3vh);
        transition: padding 1s;
    }

    h3 {
        font-size: max(1.3vw, 2.2vh);
    }

    p {
        margin: 0;
    }

    i {
        color: #4695D6;
        font-style: normal;
    }

    a {
        color: var(--text-secondary);
        text-decoration: none;

        pointer-events: all;

        transition: 0.2s ease;
    }

    a:hover {
        cursor: pointer;

        color: var(--sml);
    }

    .disabled {
        background-color: #D9544D;
    }

    button {
        margin: 20px 10px;
        padding: 10px;

        font-size: 20px;
        color: var(--text-main);

        background-color: var(--bg-secondary);

        border: none;
        border-radius: max(1vw, 1vh);

        transition: 0.3s ease;
    }

    button:hover:not(.disabled) {
        cursor: pointer;

        transform: scale(1.1, 1.1);
    }

    select {
        margin: 20px 10px;
        padding: 10px;

        font-size: 20px;
        color: var(--text-main);

        background-color: var(--bg-secondary);

        border: none;
        border-radius: max(1vw, 1vh);

        transition: 0.3s ease;

        min-width: 300px;
    }

    label {
        margin: 20px 10px;
        padding: 10px;

        font-size: 20px;
        color: var(--text-main);
    }

    input {
        margin: 20px 10px;
        padding: 10px;

        font-size: 20px;
        color: var(--text-main);

        background-color: var(--bg-secondary);

        border: none;
        border-radius: max(1vw, 1vh);

        transition: 0.3s ease;

        min-width: 300px;
    }

    input[type=checkbox] {
        min-width: 20px;
        height: 20px;
    }

    input:focus {
        background-color: #fff;
        color: #000;

        box-shadow: 0 0 1.5vmin var(--sml);

        outline: none;
    }

    /* .liveagent {
        position: fixed;
        top: 80%;
        left: 80%;
        transform: translate(-50%, -50%);
        z-index: 1000;
        width: 90%;
    } */
    /* TODO: k tomuhle se nebudu vyjadrovat - niki */

    .memberCard {
        width: 65vw;
        height: min(20vh, 20vw);

        padding: 2vh 1vw;

        margin-top: 2vh;

        display: flex;
        align-items: center;

        color: #ddd;

        font-size: 1.2vw;

        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 2vw;

        transition: 0.6s;
    }

    .memberCard:hover {
        width: 69vw;
        height: min(22vh, 23vw);
        text-shadow: 1vw black;
    }

    .memberCard img {
        margin: min(2vh, 2vw) max(1vw, 1vh);

        border-radius: 100%;
        width: max(10vw, 10vh);
        height: max(10vw, 10vh);
    }

    .privacyPolicy {
        z-index: 9999999;
    }

    .privacyPolicy p {
        margin: 10px 0;
        padding: 0 20px;
        font-size: 1em;
        width: 69vw;
    }

    .privacyPolicy p+br+p {
        margin-top: 20px;
    }

    .goBack {
        position: fixed;
        top: 5vh;
        left: 5vh;

        font-size: max(2vw, 4vh);

        transition: 0.2s;
    }

    .goBack:hover {
        font-size: max(2.1vw, 4.1vh);
    }

    /* USEFUL CLASSES */
    .row {
        display: flex;
        flex-direction: row;
    }

    .column {
        display: flex;
        flex-direction: column;
    }

    .centerAlign {
        display: flex;
        align-items: center;
        justify-content: center;

        text-align: center;
    }

    .evenSpacing {
        justify-content: space-around;
    }

    .container {
        width: 100vw;
        min-height: 100vh;
    }

    .formGroup {
        margin: 1vh 1vw;
    }

    .resetMargin {
        margin: 0;
    }

    .logo {
        font-family: "Waffle",
            -apple-system,
            BlinkMacSystemFont,
            'Segoe UI',
            'Roboto',
            'Oxygen',
            'Ubuntu',
            'Cantarell',
            'Fira Sans',
            'Droid Sans',
            'Helvetica Neue',
            sans-serif;
    }

    @media (max-width: 768px) {
        .header-container {
          min-height: 100vh;
        }
      }

    /* NavBar */
    .navBar {
        z-index: 5000;
        position: fixed;
        left: 15vw;

        padding: 0.6vh 0vw;

        width: 70vw;

        background-color: rgba(0, 0, 0, 0.6);

        filter: blur(0.02vw);

        border-radius: 2vw;

        transition: top 0.65s ease;
    }

    .navBar.hidden {
        top: -10vh;
    }

    .navBar:not(.hidden) {
        top: 3vh;
    }

    .navBar a {
        color: var(--sml);
    }

    .navBar .navOption {
        width: 10vw;
        height: 5vh;
    }

    @media (max-width:600px) {
        .sponsorLogo img {
            width: 75vw;
            height: auto;
        }

        input {
            width: 80vw;
        }

        select {
            width: 80vw;
        }
    }