
/* Welcome animation */
.welcomeAnimation {
    position: absolute;
    z-index: 100;

    width: 100vw;
    height: 100vh;

    background-color: var(--bg-main);

    animation: welcomeAnim-bgFadeOut 4s ease both;
}

.welcomeAnimation .svg {
    width: 45vw;

    animation: welcomeAnim-svg 4s ease both;
}

@keyframes welcomeAnim-svg {
    0% {
        scale: 0.8;
    }
    30% {
        scale: 0.8;
    }
    65% {
        scale: 1;
    }
}

@keyframes welcomeAnim-bgFadeOut {
    0% {
        opacity: 1;
    }
    85% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        visibility: hidden;
    }
  }


/* Header */
.header {
    width: 100vw;
    height: 100dvh;

    text-align: center;

    z-index: 5;

    background-color: var(--bg-main);
}

.header .titleContainer {
    position: absolute;
    top: 30vh;

    z-index: 10;
}

.header .title {
    /*font-size: max(5vw, 6vh);*/ /* with holiday text */
    font-size: max(7vw, 8vh);

    transition: 0.3s ease;
}

.header .title i, .header .title p {
    transition: 0.3s ease;
}

.header .title:hover p {
    transform: scale(0.9);
    color: var(--text-secondary);
}

.header .title:hover i {
    transform: scale(1.1);
}

.header .contactDetails {
    margin-top: 2vh;
}

.header .titleSlide {
    position: relative;
    width: max(12vw, 20vh);
}

.header .wave {
    position: absolute;
    bottom: -0.1vh;
    left: 0;

    z-index: 2;
}

.header .arrow {
    -webkit-animation: fade-in-top 1.5s ease-in-out both;
    animation: fade-in-top 1.5s ease-in-out both;
}

.regBtn {
    position: fixed;
    right: 10px;
    top: 5px;

    flex-direction: row;

    z-index: 1000;
}

/* Info */
.info {
    position: relative;

    min-height: 110vh;
    /*min-height: 100vh;*/

    background-color: var(--bg-main); 
}


.info * .wave, .info .wave {
    position: absolute;
    top: 0;

    z-index: 2;

    rotate: 180deg;
}

.info .secondary {
    margin-top: 4vmin;
    z-index: 1;

    animation-duration: 1s;
}

.info h2 {
    font-size: max(1.8vw, 2.3vh)
}


/* Registration */
.registration {
    position: relative;

    min-height: 30vh;

    background-color: var(--bg-main); 
}

.registration * .wave, .registration .wave {
    position: absolute;
    top: 0;

    z-index: 2;

    rotate: 180deg;
}

.registration .secondary {
    margin-top: 4vmin;
    z-index: 1;

    animation-duration: 1s;
}

.registration h1 {
    font-size: 50px;
}

.registration .countdown {
    font-size: 50px;
    font-family: "Waffle";
    text-align: center;
}

/* Reviews */
.reviews {
    position: relative;

    height: 80vh;

    align-items: center;

    background-color: var(--bg-main); 
}

.reviewContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    flex-wrap: wrap;

    width: 70vw;
    height: 40vh;

    margin-top: 5vh;
}

.reviewContainer h3 {
    width: 30vw;

    font-size: max(1.8vw,2.4vh);

    text-align: center;
}

/* FAQ */
.faqPage {
    min-height: 100vh;

    background-color: var(--bg-main);
}

.faqPage .faq {
    width: 75vw;
}

/* CONTACT */
.contact {
    position: relative;
    height: 100vh;
}

.contact h1 {
    font-size: max(3.7vw, 4.8vh);
}

.contact .footer {
    position: absolute;
    bottom: 2vh;

    color: var(--text-secondary)
}

/* HARMONOGRAM */
.harmonogram {
    position: relative;
    height: 100vh;
}

.harmonogram .events button {
    position: absolute;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 12vw;
    height: 12vw;

    border-radius: 100%;

    background-color: var(--sml);

    box-shadow: 0.8vh 0.8vh var(--text-main);
}

.harmonogram .events h2 {
    margin: 0.6vh;

    font-size: max(1.6vw,2.9vh);
}

.harmonogram .events button:hover {
    transform: scale(1) translate(0.8vh, 0.8vh);

    box-shadow: 0vw 0vh var(--text-main);
}

/* sponsors */

.sponsorContainer {
    padding-bottom: 10vh;
    padding-top: 10vh;
    background: linear-gradient(to bottom, #f8f8f8, #fff);
}

.sponsorLogo {
    transition: transform .2s;
}

.sponsorLogo img {
    width: 30vw;
}

.sponsorLogo:hover {
    transform: scale(1.1);
}