.ocean { 
    height: 5%;
    width:100%;
    position:absolute;
    bottom:0;
    left:0;
    background: #015871;
    pointer-events: none
  }
  
  .animWave { 
    position: absolute;
    bottom: 0;
    left: 0;

    width: 200vw;

    animation: wave 5s linear infinite;
    transform: translate3d(0, 0, 0);
  }

  .animWave:nth-of-type(2) {
    filter: invert(52%) sepia(82%) saturate(391%) hue-rotate(166deg) brightness(88%) contrast(89%);

    animation: swell 5s linear infinite;
  }
  
  @keyframes wave {
    0% {
      transform: translateX(0)
    }
    100% {
        transform: translateX(-75vw)
    }
  }

  @keyframes swell {
    0%, 100% {
      transform: translateY(-1vh);
    }
    50% {
      transform: translateY(0vh);
    }
  }