.faq {
    width: 100%;
    text-align: left;
}

.faq h2,h3 {
    margin: 0;
}

.faqRow {
    margin: 2vh 0vw;
}

.faqRow .faqTitle {
    padding: 0.3vh 0.1vw;

    align-items: center;

    text-align: left;

    user-select: none;
    cursor: pointer;

    border-bottom: 0.2vw solid var(--sml);
}

.faqRow .faqTitle h2 {
    margin-right: 0.4vw;

    font-size: max(1.5vw, 2vh);
}

.faqRow .dropdown {
    padding: 0.4vh 0.2vw;
    margin-bottom: 0.6vh;

    overflow: hidden;

    transition: height 0.4s ease;
}

.faqRow .content p {
    margin-left: 0.3vw;
    padding: 1vh 0vw;

    font-size: max(1.3vw, 1.8vh);
    text-align: left;
}