$obj_count: 100; //not actual obj count, but having it higher doesnt harm anything so :p

@function random_range($min, $max) {
    $rand: random();
    $random_range: $min + floor($rand * (($max - $min) + 1));
    @return $random_range;
}
    
@function sample($list) {
    @return nth($list, random(length($list)));
}

.animSep {
    position: relative;
    margin-top: 8vh;
    margin-bottom: 16vh; /* ??? */
    padding-bottom: 10px;
}

.square, .circle {
    width: var(--size);
    height: var(--size);

    background-color: var(--sml);
}

.circle {
    border-radius: 100%;
}

.triangle {
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 0 calc(var(--size)/1.9) var(--size) calc(var(--size)/1.9);
    border-color: transparent transparent var(--sml) transparent;
}

.animObj {

    overflow: hidden;
    white-space: nowrap;

    position: absolute;
    left: -10vw;
    bottom: var(--bottom-offset);

    z-index: 1;

    transform: rotate(var(--rotation));

    animation: float-right var(--right-duration) var(--right-delay) linear infinite;

    filter: blur(0.15vw);

    @for $i from 0 through $obj_count {
        &:nth-child(#{$i}) {
          --size: #{random_range(2vw, 4vw)};
          --bottom-offset: #{random_range(-4vh, 4vh)};
          --right-duration: #{random_range(20s, 24s)};
          --right-delay: #{random_range(0s,20s)};
          --rotation: #{random_range(0, 360)}deg;
        }
      }
}

@keyframes float-right {
    to {
      transform: translateX(130vw);
    }
  }