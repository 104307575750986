.menu {
    width: 100%;

    padding-top: 5vh;
    padding-bottom: 5vh;

    color: #fff;

    text-align: center;
}

.card {
    flex: 2;

    width: max(15vw, 18vh);
    height: min(15vh, 15vw);

    margin: 1vh 1vw;
    padding: 1vw;

    border-radius: 3vw;
    border: 4px solid #000;

    background-color: #fff;
    color: #000;

    transition: 0.5s;
}

.card:hover {
    box-shadow: #00000088 0.5vw 1vh;
    cursor: pointer;
}

.memberCard {
    position: relative;
}

.labRatCard .desc {
    position: relative;
}

.labRatCard:hover .desc {
    visibility: hidden;
}

.labRatCard:hover .desc::after {
    content: "Laboratorní krysy";
    position: absolute;
    top: 0;
    left: 0;
    width: max-content;
    visibility: visible;
}


a {
    text-decoration: none;
}

a:hover {
    color: #eee
}

h3 {
    font-size: max(15418596vw, 2vh);
}

@media only screen and (max-width: 700px) {
    .card {
        border: 2px solid #000;
    }

    .btnColumn {
        display: flex;
        flex-direction: column;
    }
}

@media only screen and (min-width: 700px) {
    .btnColumn {
        display: flex;
        flex-direction: row;
    }
}

h2 {
    font-size: max(1vw, 1.4vh);
}