.regForm {
  display: flex;
  flex-direction: column;
  align-items: center;

  text-align: center;

  height: 95vh;
  width: 100vw;
  overflow-y: scroll;
}

.regForm input[type="file"] {
  display: none;
}

.regForm input[type=checkbox] {
  width: 20px;
  height: 20px;
}

.regForm .teamPfpSelector {
  margin: min(0.2vh, 0.3vw) max(0.2vw, 0.3vh);
  padding: 0.6vh max(0.6vw, 0.9vh);

  font-size: max(1.4vw, 1.6vh);
  color: var(--text-main);

  background-color: var(--bg-secondary);

  border: none;
  border-radius: max(1vw, 1vh);

  transition: 0.3s ease;
  cursor: pointer;
}

.regForm .title {
  margin-top: 10vh;
}

.regForm .submit {
  margin-bottom: 10vh;
}

.regForm .memberInfo {
  padding: 5vh 5vw;
}

.regForm .memberInfo .amtSelect h3 {
  font-size: max(2vw, 2.4vh);
  cursor: pointer;
}

.amtSelect button {
  background-color: #00000000;
}